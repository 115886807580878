const urlParams = new URLSearchParams(window.location.search);
const logged = urlParams.get('logged');

window.AppConfig = {
  IS_TEST: true,
  // TEST_BRAND_DOMAIN: "http://localhost:8082",
  TEST_BRAND_DOMAIN: 'https://messaggeroveneto.gelocal.it',
  TEST_USER_LOGGED_IN: logged == 'true' ? true : false,
  TEST_USER_ID: 1,
  BASE_URL: 'https://frontend.ugc.gedidigital.it',
  SERVICES_BASE_URL: 'https://ws.login.gedidigital.it/check_subscription',
  SERVICES_ZONA: 'web',
  EDICOLA_BASE_URL: 'https://quotidiani.gelocal.it/__BRAND__/edicola/edicola.jsp',
  JWT_BASE_URL: 'https://scripts.kataweb.it/viafoura/gigya-cookie-validator/index2.php',
  REQUEST_TIMEOUT: 10000,
  GOOGLE_KEY: 'AIzaSyAbBteYqamsSQn-VPyUeJths-jUrR0DPYY',
  UGC_ITEMS_FOR_PAGE: 10,
  BRANDS_DEFAULT_ITEMS: 100,
  COOKIE_JWT_NAME: 'blaize_jwt',
};
